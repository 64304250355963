import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Property2 } from '../models/properties.models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  constructor(
    private http : HttpClient
  ) { }

  // apiUrl:String ="http://192.168.5.15:8000/api/auth/api-inmueble/inmuebles-public/filter"
  public URL_API = environment.url;
  public URL_API_FILTER=`${this.URL_API}/api/auth/api-inmueble/inmuebles-public/filter`

  data:any={}
  options = {
    params :{}
  };

  getPropertiesAPI(data:any,params:any) {
    this.options.params = params
    return this.http.post<any>(`${this.URL_API_FILTER}`,data,this.options);
  }

  sendContactForm(form){
    return this.http.post<any>(`${this.URL_API}/api/auth/cliente_interested`, form);
  }

  getPropertyBySlug(slug:String){
    return this.http.get<any>(`${this.URL_API}/api/auth/api-inmueble/inmuebles/url/${slug}`)
  }

  getOptionsSelect(){
    return this.http.get<any>(`${this.URL_API}/api/external/helpers`)
  }

  getOptionsUbication(endpoint:String,id:Number){
    return this.http.get<any>(`${this.URL_API}/api/auth/localidad/${endpoint}/${id}` )
  }
}
