import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppSettings,Settings } from 'src/app/app.settings';
@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html',
  styleUrls:['./toolbar1.component.scss']
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() changeLogo:Boolean;
  public settings: Settings;

  constructor(public appService:AppService,public appSettings:AppSettings,) { 
  }

  ngOnInit( ) { 
    this.settings = this.appSettings.settings;  

  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
}