import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  constructor(
    private http : HttpClient
  ) { }

  public URL_API = environment.url;

  options = {
    params :{}
  };
  getAgents(params: any,data=null){
    this.options.params=params
    return this.http.post<any>(`${this.URL_API}/api/auth/kwcolombia`,data,this.options)
  }

  getAgentsById(id){
    return this.http.get<any>(`${this.URL_API}/api/auth/kwcolombia/get-agent-for-id/${id}`);

  }
  getPropretiesAgent(params:any,data:any){
    this.options.params=params
    return this.http.post<any>(`${this.URL_API}/api/auth/elite/get-properties`,data,this.options)
  }
  // validateUrl(url){
  //   return this.http.get<any>(url)
  // }

  async validateUrl(url){
    this.options.params={}
    let res = await fetch(url,{method: 'HEAD', mode: 'no-cors'})
    return res
  }

}
