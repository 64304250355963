import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Country } from 'src/app/models/properties.models';
import { PropertiesService } from 'src/app/services/properties.service';



@Component({
    selector: 'app-agents-search',
    templateUrl: './agents-search.component.html',
    styleUrls: ['./agents-search.component.scss']
})
export class AgentsSearchComponent implements OnInit {
    @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
    form: FormGroup;

    countries: Country[];
    states: [];
    cities: [];
    zones: [];
    neighborhoods: []
    variant: number;


    constructor(
        private fb: FormBuilder,
        private propertiesService: PropertiesService
    ) {
        this.form = this.fb.group({
            name: null,
            email: null,
            country: null,
            state: null,
            city: null,
            zone: null,
            neighborhood: [],
        })
    }

    ngOnInit(): void {
        this.getOptionsSelectAPI()
    }


    public searchFilters() {
        this.onSearchChange.emit(this.form);
    }
    onKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            // Aquí puedes llamar a la función para activar los filtros
            this.onSearchChange.emit(this.form);

        }
    }
    getOptionsSelectAPI() {
        this.propertiesService.getOptionsSelect().subscribe(data => {
            this.countries = data.data.country
            this.selectCountryDefault()
        })
    }
    public selectCountryDefault() {
        let selectCountry = this.countries.find((country) => country.id === 48)
        this.form.controls['country'].setValue(selectCountry);
        this.onSelectCountry()
    }
    public onSelectCountry() {
        let selectPosition = { ...this.form.controls['country'].getRawValue() };
        this.propertiesService.getOptionsUbication('states', selectPosition.id).subscribe(data => {
            this.states = data.data.estados
        })
    }

    public onSelectState() {
        let selectPosition = { ...this.form.controls['state'].getRawValue() };
        this.propertiesService.getOptionsUbication('ciudades', selectPosition.id).subscribe(data => {
            this.cities = data.data.ciudades
        })
    }

    public onSelectCity() {
        let selectPosition = { ...this.form.controls['city'].getRawValue() };
        this.propertiesService.getOptionsUbication('zona', selectPosition.id).subscribe(data => {
            this.zones = data.data.zonas
        })
    }


    public onSelectZone() {
        let selectPosition = { ...this.form.controls['zone'].getRawValue() };
        this.propertiesService.getOptionsUbication('barrio', selectPosition.id).subscribe(data => {
            this.neighborhoods = data.data.barrios
        })
    }


  onSelectNeighborhood(event: any) {
    const selectedValues = this.form.controls['neighborhood'].value;
    if (selectedValues.length > 5) {
      // Limitar a los primeros 5 seleccionados
      this.form.controls['neighborhood'].setValue(selectedValues.slice(0, 5));
    }
  }

    public reset() {
        this.form.reset({
            name: null,
            email: null,
            country: null,
            state: null,
            city: null,
            zone: null,
            neighborhood: [],
        });
        this.onSearchChange.emit(this.form);
    }

    public getAppearance() {
        return (this.variant != 3) ? 'outline' : '';
    }
    public getFloatLabel() {
        return (this.variant == 1) ? 'always' : '';
    }

}
