import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { AgentsService } from 'src/app/services/agents.service';

@Component({
    selector: 'app-our-agents',
    templateUrl: './our-agents.component.html',
    styleUrls: ['./our-agents.component.scss']
})
export class OurAgentsComponent implements OnInit {
    public agents: any;
    public config: SwiperConfigInterface = {};
    constructor(
        public appService: AppService,
        public agentService: AgentsService,
    ) { }

    ngOnInit() {
        this.getAgents()
    }

    ngAfterViewInit() {
        this.config = {
            observer: true,
            slidesPerView: 4,
            spaceBetween: 16,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                600: {
                    slidesPerView: 2
                },
                960: {
                    slidesPerView: 3
                },
                1280: {
                    slidesPerView: 4
                }
            }
        }
    }

    public getAgents() {
        let params = {
            page: 1
        }
        this.agentService.getAgents(params).subscribe((res) => {
            const { total, per_page, data } = res.data
            this.agents = data
            console.log(this.agents)
            // this.tableAgents.map(async(item,index)=>{
            //   let res= await this.validateURL(item.userdata.foto_persona)
            //   if(!res){
            //     this.tableAgents[index].userdata.foto_persona = "../../../assets/images/default/user.png"
            //   }
            // })

        })
    }
    public replaceNum(num) {
        return num.replace(/^\+57/, "");
    }
}
